@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
@font-face {
    font-family: 'Mabar';
    src: url("../fonts/Mabar.otf");
    font-weight: 400;
    font-style : normal;
}

@tailwind base;

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(55, 55, 55, 1);
  border-radius: 4px;
}

@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: #090708;
    --foreground: 224 71.4% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
    --primary: 242 66% 88%;
    --primary-foreground: 242 71% 27%;
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 224 71.4% 4.1%;
    --radius: 0.5rem;
  }
}

@layer utilities {
  .slide-speed-default {
    --slide-speed: 60s;
  }

  @screen md {
    .slide-speed-md {
      --slide-speed: 140s;
    }
  }
}

@layer utilities {
  .customer-slide-speed-default {
    --customer-slide-speed: 260s;
  }

  @screen md {
    .customer-slide-speed-md {
      --customer-slide-speed: 260s;
    }
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

:root {
  /* font-family: "Mabar", sans-serif; */
  line-height: 1.5;
  font-weight: 400;

  color: rgba(255, 255, 255);
  background-color: #090708;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  /* font-weight: 500;
  color: #646cff; */
  text-decoration: inherit;
}
/* a:hover {
  color: #535bf2;
} */

body {
  margin: 0;
  min-width: 320px;
  min-height: 100vh;
  @apply bg-background font-mabar;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

.logos {
  @apply before:absolute before:left-0 before:top-0 before:z-10 before:h-full before:w-40 before:bg-gradient-to-r before:from-body_background before:to-transparent before:content-[''] before:md:w-80;
}

.logos {
  @apply before:content-[''] after:absolute after:right-0 after:top-0 after:z-10 after:h-full after:w-40 after:bg-gradient-to-l after:from-body_background after:to-transparent after:md:w-80;
}

.customers {
  @apply before:absolute before:left-0 before:top-0 before:z-10 before:h-full before:w-24 before:bg-gradient-to-r before:from-body_background before:to-transparent before:content-[''] before:md:w-60;
}

.customers {
  @apply before:content-[''] after:absolute after:right-0 after:top-0 after:z-10 after:h-full after:w-24 after:bg-gradient-to-l after:from-body_background after:to-transparent after:md:w-60;
}

.base-button {
  @apply h-11 w-fit rounded-full bg-gradient-to-r from-brand-2 to-orange-800 px-6 py-2 text-base font-medium text-white transition-all duration-500 active:scale-95
}

/* spinner */

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lds-ellipsis div {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 4.8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 4.8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 19.2px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 32px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(14.4px, 0);
  }
}


.up-down-animation6px {
  animation: up-down 1s linear infinite;
}

.up-down-animation-6 {
  @apply up-down-animation6px
}
@keyframes up-down {
  0% {
		transform: translateY(0px);
	}
	50% {
    transform: translateY(6px);
	}
  100% {
    transform: translateY(0px);
  }
}